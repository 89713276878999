.mt-none {
  margin-top: 0 !important;
}
.try-on-menu-container {
  display: block;
  width: 100%;
  background-color: #000;
  cursor: pointer !important;
  margin: 15px 0 0;
}
.try-on-menu-container svg,
.try-on-menu-container span {
  display: inline-block;
  vertical-align: middle;
}
.try-on-menu-container span {
  color: #fff;
  margin-top: 5px;
}
.try-on-menu-container svg path {
  fill: #fff;
}
button.try-on-menu-container[disabled] {
  cursor: default !important;
  opacity: 0.5;
}
.w-per100 {
  width: 100%;
  float: left;
}
.container-tryon-media {
  position: relative;
  width: 100%;
  float: left;
  height: 100%;
  background: #fff;
  color: #404040;
  padding: 0;
  border: 8px solid #28300f;
  margin: 0;
  max-width: 100%;
}
.tryon-media-content {
  float: left;
  width: 100%;
}
.tryon-media-menu {
  width: 100%;
  float: left;
  height: 100%;
  border: 3px solid #f4f3ea;
}
.container-tryon-media .tryon-main-title {
  width: 100%;
  float: left;
  font-size: 22px;
  text-align: center;
  color: #515151;
  margin-top: 40px;
}
.container-tryon-media .tryon-simple-heading {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 25px;
}
.container-tryon-media .tryon-type-btn {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 18px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 20px;
  margin: 0 0 20px;
  cursor: pointer;
}
.container-tryon-media .tryon-type-btn.disabled {
  width: 100%;
  float: left;
  pointer-events: none;
  color: #d9d9d9;
}
.tryon-product-container {
  width: 100%;
  float: left;
  padding: 20px 0;
  border-top: 2px solid #3a3a3a;
  border-bottom: 2px solid #3a3a3a;
  margin: 15px 0;
}
.tryon-product-container .tryon-product {
  float: left;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.tryon-i-accept-p1 {
  width: 100%;
  float: left;
  text-align: center;
  margin: 10px 0 30px;
  font-size: 16px;
  line-height: 20px;
  padding: 0 50px;
  cursor: pointer;
  font-weight: 400;
  color: #323232;
}
.tryon-i-accept-p1 input[type='checkbox'] {
  cursor: pointer;
  accent-color: #ffacc6 !important;
}
.tryon-i-accept-p2 {
  width: 100%;
  float: left;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  color: #323232;
  padding: 0 20px 30px;
  text-transform: inherit;
}
.tryon-i-accept-p2 a {
  color: #323232;
}
#base-canvas,
#display-canvas {
  width: 100%;
}
.container-choose-model {
  width: 100%;
  float: left;
  height: 100%;
}
.container-choose-model .model-container {
  width: calc(50% - 10px);
  float: left;
  margin-right: 10px;
  margin-top: 20px;
  cursor: pointer;
}
.container-choose-model .model-container img {
  width: 100%;
}
.container-main-tryon-back {
  cursor: pointer;
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 15px;
  text-align: center;
}
.container-main-tryon-back img {
  width: 24px !important;
}
.container-custom-tryon-range-slider .ui-widget-content {
  background: #4cc2bf !important;
}
.container-custom-tryon-range-slider .custom-tryon-range-slider {
  width: calc(50% - 2px);
  float: left;
  padding: 0;
  background: linear-gradient(
    to right,
    #4cc2bf 0%,
    #4cc2bf 50%,
    #4cc2bf 50%,
    #4cc2bf 100%
  );
  border: solid 0 #81bb23;
  border-radius: 30px;
  transition: background 0.45s ease-in;
  -webkit-appearance: none;
  min-height: 3px;
  height: 3px;
  margin-top: 12px;
}
.container-custom-tryon-range-slider .left {
  width: 25%;
  float: left;
  text-align: right;
  padding-right: 20px;
  padding-top: 2.5px;
  font-size: 15px;
  font-weight: 600;
}
.container-custom-tryon-range-slider .right {
  width: 23%;
  float: left;
  text-align: left;
  padding-left: 20px;
  padding-top: 2.5px;
  font-size: 15px;
  font-weight: 600;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #4cc2bf;
}
.makeover-section-right,
.makeover-section-left {
  width: 50%;
  float: left;
}
.makeover-container {
  width: 100%;
  float: left;
}
.no-outline {
  outline: 0;
}
.container-all-makeover {
  padding-left: 20px;
  width: calc(100% - 20px);
  float: left;
}
.makeover-product-container {
  width: 100%;
  float: left;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.makeover-product-container .makeover-product-title {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.makeover-product-container .makeover-products-listing {
  width: 100%;
  float: left;
  overflow: auto;
}
.makeover-product-container
  .makeover-products-listing
  .makeover-listing-right-container {
  width: 10%;
  float: left;
  height: 90px;
  font-size: 30px;
  padding: 30px 20px;
  color: #404040;
  cursor: pointer;
}
.makeover-product-container
  .makeover-products-listing
  .makeover-listing-center-container {
  width: 80%;
  float: left;
  height: 100%;
  overflow: hidden;
}
.makeover-product-container
  .makeover-products-listing
  .makeover-listing-left-container {
  width: 10%;
  float: left;
  height: 90px;
  font-size: 30px;
  padding: 30px 20px;
  color: #404040;
  cursor: pointer;
}
.makeover-product-container .makeover-products-listing ul {
  float: left;
  height: 90px;
  overflow: auto;
  margin: 0 auto;
  white-space: nowrap;
  width: max-content;
  padding: 10px;
}
.makeover-product-container .makeover-product-cart-container {
  width: 100%;
  float: left;
}
.makeover-product-container .makeover-products-listing .makeover-product-icon {
  float: left;
  background: red;
  height: 36px;
  width: 36px;
  display: inline;
  border-radius: 100%;
  margin-right: 20px;
  cursor: pointer;
  box-shadow: 0 0 0 2px #efefef;
  transition: box-shadow 0.5s;
  border: 2px solid #ffffff;
}
.makeover-product-container
  .makeover-products-listing
  .makeover-product-icon:hover {
  box-shadow: 0 0 0 2px #9e9d9d;
}
.makeover-product-container
  .makeover-products-listing
  .makeover-product-icon.active {
  border: 2px solid #ffffff;
  box-shadow: 0 0 9px 4px #404040;
}
.container-all-makeover ::-webkit-scrollbar {
  width: 10px;
}
.container-all-makeover ::-webkit-scrollbar-track {
  background: #404040;
}
.container-all-makeover ::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 20px;
  border: 3px solid #404040;
}
.tryon-toolbar {
  float: left;
  width: 100%;
  text-align: center;
  margin: 15px auto;
}
.tryon-toolbar .toolbar-img {
  display: inline-block;
  margin: 0 auto;
  padding: 4px 10px 0;
  cursor: pointer;
}
.tryon-toolbar .toolbar-img svg path {
  fill: #000;
}
.tryon-toolbar .toolbar-img:hover svg path {
  fill: #fff;
}
.tryon-toolbar .toolbar-img.toolbar-img-download img {
  width: 25px;
  margin-top: 10px;
  cursor: pointer;
}
.tryon-toolbar .toolbar-img.toolbar-img-zoom-in img {
  width: 30px;
  margin-top: 10px;
  cursor: pointer;
}
.tryon-toolbar .toolbar-img.toolbar-img-zoom-out img {
  cursor: pointer;
  width: 30px;
  margin-top: 10px;
}
.disabled {
  pointer-events: none;
  color: #d9d9d9;
}
.section-footer {
  width: 100%;
  float: left;
}
.product-area .product-area__details {
  float: left;
}
.product-area__media {
  width: 100%;
  float: left;
  padding: 20px 20px 0;
}
.pull-right {
  float: right;
}
.owl-nav,
.owl-dots {
  display: none;
}
.owl-item.cloned {
  width: 20px;
  float: left;
  margin-left: 35px;
}
.owl-stage-outer {
  width: 100%;
  float: left;
}
.owl-stage {
  float: left;
  width: 100%;
}
.container-all-makeover .cc-tabs__accordion__header {
  color: #404040;
}
.container-all-makeover .rte a,
#template a,
#address_tables a,
#comments a {
  border-bottom: none;
}
.makeover-indvidual-product-opacity-container {
  width: 100%;
  float: left;
  height: 50px;
}
.makeover-indvidual-product-opacity-container
  .container-custom-tryon-range-slider {
  position: unset !important;
  margin-top: 10px;
}
.makeover-reset-button {
  margin-top: 35px;
}
.tryon-section-image {
  float: left;
  width: 100%;
  margin-bottom: 25px;
}
.tryon-section-image img {
  width: 50%;
  float: left;
  margin-left: 25%;
}
.btn-tryon-text {
  margin-top: -1px;
  float: right;
  margin-left: 10px;
}
.Product__SwiperGallery--thumbs .link button span {
  color: var(--color-white);
  display: inline-block;
  font-size: 8px;
  font-weight: 700;
}
.btn_c2 {
  float: left !important;
}
.vto-button {
  float: left;
  margin-left: 15px;
}
.vto-button a {
  display: block;
  height: 40px;
  text-align: center;
  padding: 1px 17px 0 10px;
  border: 1px solid #81bb23;
}
.vto-button svg {
  display: inline-block;
  vertical-align: middle;
}
.vto-button svg g {
  fill: #81bb23;
}
.vto-button a:hover svg g {
  fill: #fff;
}
.vto-button span.camera-icon {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 19px;
  max-width: 100%;
  background: url(https://cdn.shopify.com/s/files/1/0245/5023/t/42/assets/camera.png?v=4414057303245718040)
    no-repeat;
  background-size: contain;
}
.vto-button a span.tryon-text {
  display: inline-block;
  vertical-align: middle;
  color: #81bb23;
  font-size: 16px;
  font-weight: 700;
  line-height: 37px;
}
.vto-button a:hover {
  background-color: #424940;
  border: 1px solid #424940;
}
.vto-button a:hover span.camera-icon {
  /* background: url(./images/camera-icon-white.png) no-repeat; */
  background-size: contain;
}
.vto-button a:hover span.tryon-text {
  color: #fff;
}
.vtocr {
  float: left;
  clear: left;
  margin-left: 0;
  margin-top: 30px;
}
.modal-fullwidth-image img {
  max-width: 100%;
}
.eyeshadows {
  float: left;
  width: 100%;
  padding: 20px 15px;
}
.eyeshadows ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.eyeshadows a {
  display: block;
  width: auto;
  max-width: 55px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #eee;
}
.eyeshadows a.active {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px #00000080;
}
.eyeshadows a img {
  max-width: 100%;
}
.eyeshadows-colors {
  float: left;
  padding: 0;
  margin: 10px 0 20px;
}
.eyeshadows-colors ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.eyeshadows-colors ul li {
  margin-right: 10px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid #a9a9a9;
  cursor: pointer;
}
.eyeshadows-colors ul li.active {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px #0009;
}
.slider-vtobutton {
  display: none;
}
.vto-button.vto_mobile {
  display: inline;
  position: relative;
  top: -50px;
  z-index: 10;
}
@media only screen and (max-width: 800px) and (min-width: 300px) {
  .makeover-section-right,
  .makeover-section-left {
    width: 100%;
    float: left;
  }
  .makeover-product-container
    .makeover-products-listing
    .makeover-listing-center-container {
    width: 70%;
  }
  .section-footer {
    width: 100%;
    float: left;
  }
  .makeover-product-cart-container .quantity-wrapper.border-radius-sharp {
    width: 49%;
    float: left;
    margin-right: 1%;
  }
  .makeover-product-cart-container .button {
    width: 49%;
    float: left;
    margin-left: 1%;
    border-color: #bbb;
    height: 58px;
  }
}
@media (max-width: 767px) {
  .container-tryon-media {
    border: 0 solid #f6f6f6;
  }
  .vto-button span.camera-icon {
    display: block;
    margin: 0 auto;
    width: 15px;
    height: 12px;
  }
  .vto-button a span.tryon-text {
    margin-left: 0;
    font-size: 12px;
    line-height: 16px;
  }
}
@media (max-width: 798px) {
  .container-tryon-media {
    max-width: 100vw;
    width: 100vw;
    margin: 0 -20px 5px;
    padding: 0;
    border: 0 solid #f6f6f6;
  }
  .canvas-container{
    padding-bottom: 100%; 
  }
  #try-on-area{
    position: relative;
  }
}
@media (max-width: 767px) {
  .qty {
    padding-right: 5px;
  }
  .btn_c2 {
    max-width: 120px;
    padding: 0 10px;
    font-size: 14px;
  }
  .vto-button {
    margin-left: 5px;
  }
  .vto-button svg {
    display: block;
    margin: -7px auto -10px;
  }
  .vto-button a {
    padding: 5px 5px 0;
  }
  .slider-vtobutton {
    display: block;
    position: absolute;
    bottom: 14%;
    left: 10px;
    z-index: 99;
  }
  .product-layout-design {
    padding-top: 0 !important;
  }
}
#containerEyeMasks {
  width: 100%;
  float: left;
  margin: 15px auto 25px;
}
#containerEyeMasks .containerEyeMask {
  width: auto;
  float: left;
  margin-bottom: 15px;
  margin-right: 5px;
}
#containerEyeMasks .containerEyeMask.active img {
  cursor: pointer;
  max-width: 82px;
  outline: 3px solid white;
  border: 3px solid #81bb23;
  border-radius: 100%;
}
.no-makeup-icon {
  width: 67px !important;
}
.eyechadow-color-swach {
  width: 60px;
  float: left;
  height: 60px;
  margin-right: 20px;
  border-radius: 100%;
  box-shadow: 1px 1px 11px 4px #a9a8a8;
  cursor: pointer;
}
.eyechadow-color-swach[value='Ivory'] {
  background: #fff0e1;
}
.eyechadow-color-swach[value='Flesh'] {
  background: #f5e3d4;
}
.eyechadow-color-swach[value='Cocoa'] {
  background: #86635a;
}
.eyechadow-color-swach[value='Charcoal'] {
  background: #646165;
}
.eyechadow-color-swach[value='Stone'] {
  background: #c2a18e;
}
.eyechadow-color-swach[value='Sparkling Champagne'] {
  background: #d6b5a4;
}
.eyechadow-color-swach[value='Topaz Shimmer'] {
  background: #af897a;
}
.eyechadow-color-swach[value='Spun Gold'] {
  background: #ebd1c4;
}
.eyechadow-color-swach.active {
  outline: 3px solid white;
  border: 3px solid #81bb23;
}
.clear-both-description {
  clear: both !important;
}
.desktop-hide {
  display: none !important;
}
.mobile-hide {
  display: block !important;
}
@media (max-width: 798px) {
  .desktop-hide {
    display: block !important;
  }
  .mobile-hide {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .desktop-hide .spr-header-title {
    font-weight: 400 !important;
  }
  .container-custom-tryon-range-slider {
    margin-top: 10px;
    height: 20px;
  }
  #content .pro_main_c .slider_1 {
    padding: 20px 15px 15px !important;
    margin: 0 !important;
  }
}
.container-custom-tryon-range-slider {
  position: relative;
  width: calc(50% - 2px);
  height: auto;
  float: none;
  margin: 30px auto 10px;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 0px solid #c5c5c5 !important;
  background: #000 !important;
  font-weight: 400;
  color: #454545;
  border-radius: 50%;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -10px !important;
}
.ui-widget.ui-widget-content {
  border: 2px solid #000 !important;
  position: relative;
  left: -5px;
  top: -2px;
}
.ui-slider-horizontal {
  height: 0 !important;
}
.less-intensity,
.more-intensity {
  position: absolute;
  top: -14px;
  font-size: 15px;
  font-weight: 600;
  color: #404040;
}
.less-intensity {
  left: -50px;
}
.more-intensity {
  right: -50px;
}
.container-product-image {
  position: relative;
}
.image__container {
  position: relative !important;
}
.image-overlay-button {
  position: absolute !important;
  z-index: 2;
  top: 10px;
  right: 75px;
}
.product__media-toggle {
  z-index: 1 !important;
}
@media (max-width: 991px) {
  .image-overlay-button {
    right: auto;
  }
}
.image-overlay-button button {
  min-width: auto;
  min-height: auto;
  margin: 0 0 0 2px !important;
  padding: 4px 10px 8px;
  height: auto !important;
  border-radius: 0 !important;
}
.image-overlay-button button svg {
  display: block;
  margin: 0 auto;
}
.image-overlay-button button svg g {
  fill: #fff;
}
.image-overlay-button button span {
  font-size: 12px;
  color: #fff;
  margin-top: 0 !important;
}
.image-overlay-button button {
  display: block !important;
}
.tryon-button-container .button:disabled,
.image-overlay-button .button:disabled {
  cursor: not-allowed;
  opacity: 1;
  background-color: #000 !important;
  border: 1px solid #000 !important;
  box-shadow: 0 0 0 0.1rem #f7f7f7 !important;
  color: #fff;
}
@media (max-width: 767px) {
  .image-overlay-button button {
    min-width: 105px;
  }
}
.tryon-button-container .button#tryOn:disabled:hover,
.image-overlay-button .button#tryOn:disabled:hover {
  box-shadow: 0 0 0 0.1rem #000 !important;
}
.image-overlay-button button:disabled svg path {
  fill: #fff;
}
.hide-shade {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  width: 100%;
  font-size: 11px;
  text-align: center;
  background-color: #000c;
  color: #fff;
  border-radius: 5px;
  min-width: 250px;
  opacity: 1 !important;
}
.single-option-selector.active-lash {
  border: 2px solid #ffc4d6 !important;
}
.disabledLabel {
  pointer-events: none;
  color: #ccc;
}
.unabledLabel {
  pointer-events: inherit;
  color: #000;
}
.lastSelection {
  display: block;
  margin: 20px auto 15px;
}

.container_af_loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.container_af_loader .af_loader {
  width: 200px;
  height: 200px;
  /* background-image: url('../images/af_loading.svg'); */
  object-fit: cover;
  background-repeat: no-repeat;
}
.container_af_loader p {
  margin: 0;
}
.procuct-color > li > a > span.color {
  background: rgba(193, 82, 95, 0);
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.procuct-color > li:nth-child(1) > a > span.color {
  background: rgba(193,82,95,1);
}
.procuct-color > li:nth-child(2) > a > span.color {
  background: rgba(178,99,103,1);
}
.procuct-color > li:nth-child(3) > a > span.color {
  background: rgba(205,74,92,1);
}
.procuct-color > li:nth-child(4) > a > span.color {
  background: rgba(232,44,87,1);
}
.procuct-color > li:nth-child(5) > a > span.color {
  background: rgba(227,7,31,1);
}
.procuct-color > li:nth-child(6) > a > span.color {
  background: rgba(226,120,121,1);
}
.procuct-color > li:nth-child(7) > a > span.color {
  background: rgba(113,27,47,1);
}
.procuct-color > li:nth-child(8) > a > span.color {
  background: rgba(237,54,55,1);
}
.procuct-color > li:nth-child(9) > a > span.color {
  background: rgba(219,101,131,1);
}
.procuct-color > li:nth-child(10) > a > span.color {
  background: rgba(151,72,62,1);
}
.add-to-link button.product-add-btn {
  margin: 0 10px 0 0!important;
}